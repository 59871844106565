
import { mixins, Options, Vue } from "vue-class-component";
import ProductTile from "@/components/products/ProductTile.vue";
import NewsletterForm from "@/components/misc/NewsletterForm.vue";
import SplideCarousel from "@/components/carousel/SplideCarousel.vue";
import CarouselBanner from "@/components/banners/CarouselBanner.vue";
import ClientSection from "@/components/clients/ClientSection.vue";
import FeaturedAll from "@/components/featured/FeaturedAll.vue"
import { LoadImage, SetMetaData, UploadPath } from "@/mixins/utilities";
import { BannerItems, Clients, GeneralSettings } from "@/network/api";
import { BannerItemListViewModel, ClientViewModel } from "@/api-client";
import InstagramCarousel from "@/components/instagram/InstagramCarousel.vue"
import ChooseCreateOrderBanner from "@/components/banners/ChooseCreateOrderBanner.vue"
import TrustpilotBanner from "@/components/banners/TrustpilotBanner.vue"
import { useMeta } from "vue-meta";
import DesignNowSvg from "@/components/misc/DesignNowSvg.vue";

@Options({
  components: {
    NewsletterForm,
    CarouselBanner,
    ProductTile,
    SplideCarousel,
    ClientSection,
    DesignNowSvg,
    InstagramCarousel,
    ChooseCreateOrderBanner,
    TrustpilotBanner,
    FeaturedAll
  },
})
export default class HomePage extends mixins(LoadImage, UploadPath, SetMetaData) {
  banners: Array<BannerItemListViewModel> = [];
  tempBannerArray: Array<any> = [];
  clientLogos: Array<ClientViewModel> = []

  created() {
    this.loadInitialData();
  }

  mounted() {
    const { meta } = useMeta({});

    this.setMetaData(
      meta,
      {
        script: {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "CLIFTON",
            alternateName: "CLIFTON CONCEPTS LLC.",
            url: "https://www.cliftonclothing.com/",
            logo: "https://www.cliftonclothing.com/img/nav-logo-with-slogan.a58d3d9f.png",
            sameAs: ["https://www.facebook.com/love.clifton.clothing/", "https://twitter.com/cliftonclothing", "https://www.instagram.com/cliftonclothing/", "https://www.linkedin.com/company/clifton-clothing/", "https://www.pinterest.com/cliftonclothing/", "https://www.tiktok.com/@cliftonclothing"],
          },
        },
      },
      "script",
      "Home | "
    );
  }

  loadClientLogos() {
    Clients.clientsGet()
    .then((res) => {
      if(res) {
        this.clientLogos = res.data.resultData as Array<ClientViewModel>;
      }
    })
  }

  loadBannerItems() {
    BannerItems.bannerItemsGet()
    .then((res) => {
      if (res) {
        this.banners = res.data.resultData as Array<BannerItemListViewModel>;
      }
    })
    .catch((error) => {
      console.log(error);
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  loadInitialData() {
    this.loadBannerItems()
    this.loadClientLogos()
  }
}
