
import { mixins, Options, Vue } from "vue-class-component";
import GenericPage from "@/components/general-information/GenericPage.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedCategories from "@/components/featured/FeaturedCategories.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    GenericPage, StatementBanner, FeaturedCategories 
  }
})
export default class PrivacyPolicyPage extends mixins(SetMetaData) {
  content = `<p><strong>How we use your personal information on our website.</strong></p>
    <p>This privacy policy sets out how Clifton Concepts LLC t/a CLIFTON uses and protects any information that you give Clifton Concepts LLC t/a CLIFTON when you use this website.</p>
    <p>Clifton Concepts LLC t/a CLIFTON is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
    <p>Clifton Concepts LLC t/a CLIFTON may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 20 June 2016.</p>
    <p><strong>What we collect</strong></p>
    <p>We may collect the following information:</p>
    <ul>
      <li>Name and date of birth</li>
      <li>Contact information including email address, postal/billing address and phone number</li>
      <li>Demographic information such as postcode, preferences and interests</li>
      <li>Other information relevant to customer surveys and/or offers</li>
    </ul>
    <p><strong>What we do with the information we gather</strong></p>
    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
    <ul>
      <li>Internal record keeping.</li>
      <li>We may use the information to improve our products and services.</li>
      <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
      <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone or mail. We may use the information to customise the website according to your interests.</li>
    </ul>
    <p><strong>Security</strong></p>
    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
    <p><strong>How long do we keep your information for?</strong></p>
    <p>In general we retain the information you provide either while your account is in existence, or as needed to be able to provide the Services to you, or (in the case of any contact you may have with our Studio team) for as long as is necessary to provide support-related reporting and trend analysis only.</p>
    <p>If legally required or if it is reasonably necessary to meet regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce our Terms and Conditions, we may also retain some of your information for a limited period of time as required, even after you have closed your account or it is no longer needed to provide Services to you.</p>
    <p><strong>How we use cookies</strong></p>
    <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
    <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
    <p>Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
    <p>You can see more information on cookies by taking a look at our ‘Cookies’ page.</p>
    <p><strong>Links to other websites</strong></p>
    <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
    <p><strong>Controlling your personal information</strong></p>
    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
    <ul>
      <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
      <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a>. You can also opt out by unticking the box in your account.</li>
      <li>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.</li>
    </ul>
    <p>You may request details of personal information which we hold about you for a small fee. If you would like a copy of the information held on you please email <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a></p>
    <p>If you believe that any information we are holding on you is incorrect or incomplete, please email us as soon as possible. We will promptly correct any information found to be incorrect.</p>
    <p><strong>Data Deletion</strong></p>
    <p>We respect your privacy. If you request the deletion of your personal data, we will take action to ensure that it is securely and entirely removed from our servers or anonymized. Rest assured that your information is in good hands.</p>
    <p>Should you wish for CLIFTON to delete your data, please send an email to <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a> with the subject 'Please delete my CLIFTON account' and confirm the email address you have created a CLIFTON profile with, and we will delete your account and confirm this with you via email.</p>`;

    created() {
      const {meta} = useMeta({})
    
      this.setMetaData(meta, {}, '', 'Privacy Policy | ')
    }
}
