import {
  createRouter,
  createWebHistory,
  RouteLocation,
  RouteRecordRaw,
} from "vue-router";
import { store } from "@/store";

// import DebugPage from "@/views/DebugPage.vue";
import DesignerPage from "@/views/designer/DesignerPage.vue";
import LoginPage from "@/views/user/LoginPage.vue";
import RegistrationPage from "@/views/user/RegistrationPage.vue";
import ForgotPasswordPage from "@/views/user/ForgotPasswordPage.vue";
import HomePage from "@/views/home/HomePage.vue";
import AboutUsPage from "@/views/general-information/AboutUsPage.vue";
import OurValuesPage from "@/views/general-information/OurValuesPage.vue";
import HowItWorksPage from "@/views/general-information/HowItWorksPage.vue";
import CanYouMakeThisPage from "@/views/general-information/CanYouMakeThisPage.vue";
import CustomStoreInfoPage from "@/views/general-information/CustomStoreInfoPage.vue";
import FAQsPage from "@/views/general-information/FAQsPage.vue";
import TncsPage from "@/views/legal/TncsPage.vue";
import PrivacyPolicyPage from "@/views/legal/PrivacyPolicyPage.vue";
import DataDeletionPage from "@/views/legal/DataDeletionPage.vue";
import PageNotFoundPage from "@/views/misc/PageNotFoundPage.vue";
import ProfilePage from "@/views/user/profile/ProfilePage.vue";
import CategoriesPage from "@/views/products/CategoriesPage.vue";
import ProductPage from "@/views/products/ProductPage.vue";
import BlogPostsPage from "@/views/blog/BlogPostsPage.vue";
import BlogPostPage from "@/views/blog/BlogPostPage.vue";
import CampaignPage from "@/views/campaigns/CampaignPage.vue";
import QuotePage from "@/views/checkout-process/quote/QuotePage.vue";
import PrintQuotePage from "@/views/checkout-process/print-quote/PrintQuotePage.vue";
import CartPage from "@/views/checkout-process/cart/CartPage.vue";
import CheckoutPage from "@/views/checkout-process/checkout/CheckoutPage.vue";
import PaymentCompletePage from "@/views/checkout-process/checkout-complete/PaymentCompletePage.vue";
import OutstandingPaymentCompletePage from "@/views/checkout-process/extra-payments/OutstandingPaymentCompletePage.vue";
import OrderPaymentsPage from "@/views/checkout-process/extra-payments/OrderPaymentsPage.vue";
import ResupplyAssetsPage from "@/views/user/ResupplyAssetsPage.vue";
import EmailVerificationPage from "@/views/user/EmailVerificationPage.vue";
import ProductSelectorPage from "@/views/designer/ProductSelectorPage.vue";
import EFTPaymentPage from "@/views/checkout-process/checkout-complete/EFTPaymentPage.vue";
import EnquiryPage from "@/views/enquiries/EnquiryPage.vue";
import CheckOrderStatusPage from "@/views/checkout-process/checkout-complete/CheckOrderStatusPage.vue";
import CustomStoreOrderPage from "@/views/custom-stores/CustomStoreOrderPage.vue";
import CustomStoreCustomerPaymentCompletePage from "@/views/custom-stores/CustomStoreCustomerPaymentCompletePage.vue";
import CustomStoreAdminStorePage from "@/views/custom-stores/CustomStoreAdminStorePage.vue";
import ShippingAndReturnsPage from "@/views/legal/ShippingAndReturnsPage.vue"; 
import CookiePolicyPage from "@/views/legal/CookiePolicyPage.vue";

function authGuard(to: RouteLocation) {
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  if (isLoggedIn) {
    // logged in so return true
    return true;
  }
  // not logged in so redirect to login page with the return url
  router.push({ name: "Login", params: {lang: router.currentRoute.value.params.lang}, query: { returnUrl: to.fullPath } });
  return false;
}

function correctCart(to: RouteLocation) {
  if(to.query.cart) {
    router.push({ name: 'Cart', params: { id: to.query.cart as string, lang: router.currentRoute.value.params.lang } })
  }
}

const pathPrefix = "/:lang([a-z]{2})?";

const routes: Array<RouteRecordRaw> = [
  {
    path: pathPrefix,
    name: "Home",
    component: HomePage,
    props: true
  },
  // {
  //   path: "/debug",
  //   name: "Debug",
  //   component: DebugPage,
  //   component: () => import(/* webpackChunkName: "Debug" */ '@/views/DebugPage.vue'),
  // },
  {
    path: pathPrefix + "/designer/product-selector",
    redirect: (to) => {
      const lang = to.params.lang || undefined;
      return `${lang ? "/" + lang: ""}/designer/product-selector/all`;
    },
    name: "AllProductSelector",
    component: ProductSelectorPage,
    // component: () => import(/* webpackChunkName: "AllProductSelector" */ '@/views/designer/ProductSelectorPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/designer/product-selector/:slug*",
    name: "ProductSelector",
    component: ProductSelectorPage,
    // component: () => import(/* webpackChunkName: "ProductSelector" */ '@/views/designer/ProductSelectorPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/designer",
    name: "Designer",
    component: DesignerPage,
    // component: () => import(/* webpackChunkName: "Designer" */ '@/views/designer/DesignerPage.vue'),
    props: true,
  }, 
  {
    path: pathPrefix + "/login",
    name: "Login",
    component: LoginPage,
    // component: () => import(/* webpackChunkName: "Login" */ '@/views/user/LoginPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/register",
    name: "Register",
    component: RegistrationPage,
    // component: () => import(/* webpackChunkName: "Register" */ '@/views/user/RegistrationPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/reset-password",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
    // component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/user/ForgotPasswordPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/confirm-email",
    name: "EmailVerification",
    component: EmailVerificationPage,
    // component: () => import(/* webpackChunkName: "EmailVerification" */ '@/views/user/EmailVerificationPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/all",
    name: "AllCategories",
    component: CategoriesPage,
    // component: () => import(/* webpackChunkName: "AllCategories" */ '@/views/products/CategoriesPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/all/:slug+",
    name: "Categories",
    component: CategoriesPage,
    // component: () => import(/* webpackChunkName: "Categories" */ '@/views/products/CategoriesPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/all/:categorySlug+/product/:productSlug",
    name: "Product",
    component: ProductPage,
    // component: () => import(/* webpackChunkName: "Product" */ '@/views/products/ProductPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/profile",
    name: "Profile",
    component: ProfilePage,
    // component: () => import(/* webpackChunkName: "Profile" */ '@/views/user/profile/ProfilePage.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: pathPrefix + "/quote/:id",
    name: "Quote",
    component: QuotePage,
    // component: () => import(/* webpackChunkName: "Quote" */ '@/views/checkout-process/quote/QuotePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/cart/:id?/:accessKey?",
    name: "Cart",
    component: CartPage,
    // component: () => import(/* webpackChunkName: "Cart" */ '@/views/checkout-process/cart/CartPage.vue'),
    props: true,
    beforeEnter: correctCart
  },
  {
    path: pathPrefix + "/store-cart/:id",
    name: "StoreCart",
    component: CartPage,
    // component: () => import(/* webpackChunkName: "Cart" */ '@/views/checkout-process/cart/CartPage.vue'),
    props: true,
  },
  {
    path: pathPrefix + "/checkout/:id/:accessKey?",
    name: "Checkout",
    component: CheckoutPage,
    // component: () => import(/* webpackChunkName: "Checkout" */ '@/views/checkout-process/checkout/CheckoutPage.vue'),
    props: true,
    beforeEnter: correctCart
  },
  {
    path: pathPrefix + "/print-quote/:id",
    name: "PrintQuote",
    component: PrintQuotePage,
    // component: () => import(/* webpackChunkName: "PrintQuote" */ '@/views/checkout-process/print-quote/PrintQuotePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/payment-complete/:id/:accessKey?",
    name: "PaymentComplete",
    component: PaymentCompletePage,
    // component: () => import(/* webpackChunkName: "PaymentComplete" */ '@/views/checkout-process/checkout-complete/PaymentCompletePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/outstanding-payment-complete/:id",
    name: "OutstandingPaymentComplete",
    component: OutstandingPaymentCompletePage,
    // component: () => import(/* webpackChunkName: "OutstandingPaymentComplete" */ '@/views/checkout-process/extra-payments/OutstandingPaymentCompletePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/bank-transfer-details",
    name: "EFTPaymentPage",
    component: EFTPaymentPage,
    // component: () => import(/* webpackChunkName: "EFTPaymentPage" */ '@/views/checkout-process/checkout-complete/EFTPaymentPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/order-payments/:id",
    name: "OrderPayments",
    component: OrderPaymentsPage,
    // component: () => import(/* webpackChunkName: "OrderPayments" */ '@/views/checkout-process/extra-payments/OrderPaymentsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/online-store-payment-complete/:id",
    name: "CustomStoreCustomerPaymentComplete",
    component: CustomStoreCustomerPaymentCompletePage,
    // component: () => import(/* webpackChunkName: "CustomStoreCustomerPaymentComplete" */ '@/views/custom-stores/CustomStoreCustomerPaymentCompletePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/online-store-admin/:id",
    name: "CustomStoreAdminStore",
    component: CustomStoreAdminStorePage,
    // component: () => import(/* webpackChunkName: "CustomStoreCustomerPaymentComplete" */ '@/views/custom-stores/CustomStoreAdminStorePage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/online-store/:id",
    name: "CustomStoreOrder",
    component: CustomStoreOrderPage,
    // component: () => import(/* webpackChunkName: "CustomStoreOrder" */ '@/views/custom-stores/CustomStoreOrderPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/check-order-status",
    name: "CheckOrderStatus",
    component: CheckOrderStatusPage,
    // component: () => import(/* webpackChunkName: "OrderPayments" */ '@/views/checkout-process/extra-payments/OrderPaymentsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/resupply-assets/:id",
    name: "ResupplyAssets",
    component: ResupplyAssetsPage,
    // component: () => import(/* webpackChunkName: "ResupplyAssets" */ '@/views/user/ResupplyAssetsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/blog",
    name: "Blog",
    component: BlogPostsPage,
    // component: () => import(/* webpackChunkName: "Blog" */ '@/views/blog/BlogPostsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/blog/post/:slug+",
    name: "BlogPost",
    component: BlogPostPage,
    // component: () => import(/* webpackChunkName: "BlogPost" */ '@/views/blog/BlogPostPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/pages/:slug",
    name: "CampaignPage",
    component: CampaignPage,
    // component: () => import(/* webpackChunkName: "CampaignPage" */ '@/views/campaigns/CampaignPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/about-us",
    name: "AboutUs",
    component: AboutUsPage,
    // component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/general-information/AboutUsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/our-values",
    name: "OurValues",
    component: OurValuesPage,
    // component: () => import(/* webpackChunkName: "OurValues" */ '@/views/general-information/OurValuesPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/how-it-works",
    name: "HowItWorks",
    component: HowItWorksPage,
    // component: () => import(/* webpackChunkName: "HowItWorks" */ '@/views/general-information/HowItWorksPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/special-requests",
    name: "CanYouMakeThis",
    component: CanYouMakeThisPage,
    // component: () => import(/* webpackChunkName: "CanYouMakeThis" */ '@/views/general-information/CanYouMakeThisPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/can-you-make-me-this",
    redirect: "/special-requests",
    name: "CanYouMakeThisOld",
    props: true
  },
  {
    path: pathPrefix + "/create-an-online-store",
    name: "CustomStoreInfo",
    component: CustomStoreInfoPage,
    // component: () => import(/* webpackChunkName: "CustomStoreInfo" */ '@/views/general-information/CustomStoreInfoPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/create-a-custom-store",
    redirect: "/create-an-online-store",
    name: "CustomStoreInfoOld",
    props: true
  },
  {
    path: pathPrefix + "/contact-us",
    name: "Enquiries",
    component: EnquiryPage,
    // component: () => import(/* webpackChunkName: "Enquiries" */ '@/views/enquiries/EnquiryPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/faqs",
    name: "FAQs",
    component: FAQsPage,
    // component: () => import(/* webpackChunkName: "FAQs" */ '@/views/general-information/FAQsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/terms-and-conditions",
    name: "Tncs",
    component: TncsPage,
    // component: () => import(/* webpackChunkName: "Tncs" */ '@/views/legal/TncsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage,
    // component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/legal/PrivacyPolicyPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicyPage,
    // component: () => import(/* webpackChunkName: "CookiePolicy" */ '@/views/legal/CookiePolicyPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/delete-my-data",
    name: "DataDeletion",
    component: DataDeletionPage,
    // component: () => import(/* webpackChunkName: "DataDeletion" */ '@/views/legal/DataDeletionPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/shipping-and-returns",
    name: "ShippingAndReturns",
    component: ShippingAndReturnsPage,
    // component: () => import(/* webpackChunkName: "ShippingAndReturns" */ '@/views/legal/ShippingAndReturnsPage.vue'),
    props: true
  },
  {
    path: pathPrefix + "/404",
    name: "PageNotFound",
    component: PageNotFoundPage,
    // component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/misc/PageNotFoundPage.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFoundPage,
    beforeEnter() {
      window.location.replace(window.location.origin + "/404")
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // scroll to top except in profile
    if(router.currentRoute.value.name !== 'Profile') {
      return { top: 0, behavior: 'smooth' }; 
    }
  },
});

router.beforeEach((to, from) => {
  if (router.currentRoute.value.name == "PageNotFound") {
    window.top!.location.assign(window.top?.location.origin + to.fullPath);
  }
  
  if (document.getElementsByClassName("modal-backdrop")[0]) {
    document.getElementById("searchModalButton")?.click();
    document.getElementById("productMenuMobile")?.click();
  }

  if (document.getElementById("currency-dropdown")) {
    document.getElementById("currency-dropdown")?.classList?.remove("menu-dropdown-open");
  }

  if (document.getElementById("product-dropdown")) {
    document.getElementById("product-dropdown")?.classList?.remove("menu-dropdown-open");
  }

  document.getElementById("navbarCollapse")?.classList?.remove("show");
});

router.afterEach((to, from) => {
  if(router.currentRoute.value.name === 'Designer') {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.display = `none`;
    }
  } else {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.display = `block`;
    }
  }

  const exceptionRoutes = ['OutstandingPaymentComplete', 'PaymentComplete', 'EFTPaymentPage', 'ProductSelector', 'Cart']
    if(!exceptionRoutes.includes(router.currentRoute.value.name as string)) {
      store.dispatch("location/getDefaultSelection")
      .then(() => {
          const quoteId = router.currentRoute.value.query.quote;
          const accessKey = router.currentRoute.value.query.accessKey;
          store.dispatch("checkout/getCart", {
            quoteId: quoteId || undefined,
            accessKey: accessKey || undefined
          })
          
          const isLoggedIn = store.getters["auth/isLoggedIn"];
          if(isLoggedIn) {
            store.dispatch("user/getProfile");
          }
      })
    }
})

export default router;
