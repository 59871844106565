
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import InfoPageParagraph from "@/components/general-information/InfoPageParagraph.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    Breadcrumbs, StatementBanner, FeaturedProducts, InfoPageParagraph, HeadingBanner
  }
})
export default class OurValuesPage extends mixins(SetMetaData) {
  content = [
    {
      title: "MADE TO ORDER",
      text: "We only start to make items once you have placed your order, and never produce products beyond demand.",
      src: '/img/our-values/shirt.png'
    },
    {
      title: "SUSTAINABLE PRODUCTION",
      text: 'All our fabrics, inks and dyes are tested to be AZO free, eco-friendly and free of hazardous substances.',
      src: '/img/our-values/rewind.png'
    },
    {
      title: "PLASTIC FREE",
      text: "We're against single use plastic, which is why you'll receive your order in 100% compostable bags made from natural polymers.",
      src: '/img/our-values/blocks.png'
    },
    {
      title: "CARBON NEUTRAL DELIVERY",
      text: "All of our deliveries are made carbon neutral, as a matter of principle (don't worry, it's never at an extra cost to you).",
      src: '/img/our-values/leaf.png'
    },
    {
      title: "Ethical Supply Chain",
      text: "We're incredibly proud that every aspect of our production facility has been independently ethically certified sustainable.",
      src: '/img/our-values/single.png'
    },
    {
      title: "ISO9001 QUALITY MANAGEMENT",
      text: "As part of our commitment to quality production we are pleased to say we are an ISO9001 certified business.",
      src: '/img/our-values/star.png'
    }
  ]

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Clifton Values | ')
  }
}
